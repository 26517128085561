import React, { useState } from "react";
import { Grid, Row, Col, Dropdown } from "rsuite";
import HeaderLarge from "components/section/headerLarge";
import LayoutMain from "components/layout/main";
import SectionWrapper from "components/section/sectionWrapper";
import ContactFormProf from "../components/ui/contactFormProf";
import ContactFormPatient from "../components/ui/contactFormPatient";
const ContactPage = () => {
  const [formSelect, setFormSelect] = useState("");

  return (
    <LayoutMain>
      <HeaderLarge
        title="Contact"
        description="Heb jij een vraag of een opmerking over de inhoud van allesoverantistolling.nl? We horen dat graag. Maak eerst de keuze met welke organisatie je contact zoekt. Je kunt jouw vraag of opmerking doorgeven via onderstaand contactformulier. We nemen dan contact met je op."
      />
      <div style={{ backgroundColor: "#F6F4F5" }}>
        <SectionWrapper padded>
          <Grid>
            <Row>
              <Col>
                <h3 style={{ marginBottom: "20px", color: "#000" }}>
                  Maak een keuze
                </h3>
                <Dropdown title="Ik zoek contact over.." appearance="primary">
                  <Dropdown.Item onClick={() => setFormSelect("prof")}>
                    De professional (via Nederlandse Internisten Vereniging)
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFormSelect("patient")}>
                    De patiënt (via Trombosestichting Nederland)
                  </Dropdown.Item>
                </Dropdown>
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
      </div>
      {formSelect !== "" && (
        <SectionWrapper padded>
          <Grid>
            <Row>
              <Col>
                {formSelect === "prof" && (
                  <>
                    <h3 style={{ marginBottom: "20px", color: "#000" }}>
                      Professional
                    </h3>
                    <ContactFormProf />
                  </>
                )}
                {formSelect === "patient" && (
                  <>
                    <h3 style={{ marginBottom: "20px", color: "#000" }}>
                      Patiënt
                    </h3>
                    <ContactFormPatient />
                  </>
                )}
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
      )}
    </LayoutMain>
  );
};

export default ContactPage;
